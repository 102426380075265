import { updateCameraSequence, updateCameraPositions } from "./cameraState";

class WebSocketService {
    private ws: WebSocket | null = null;
    private onConnectCallback: (() => void) | null = null;
    private onDisconnectCallback: (() => void) | null = null;
    private onMessageReceivedCallback: ((data: any) => void) | null = null;
    private frameCount: number = 0;

    setOnConnectCallback(callback: () => void | null) {
        this.onConnectCallback = callback;
    }

    setOnDisconnectCallback(callback: () => void | null) {
        this.onDisconnectCallback = callback;
    }

    setOnMessageReceivedCallback(callback: (data: any) => void | null) {
        this.onMessageReceivedCallback = callback;
    }

    connect() {
        this.ws = new WebSocket("ws://localhost:9001");

        this.ws.onopen = () => {
            console.log("WebSocket connected");
            if (this.onConnectCallback) {
                this.onConnectCallback();
            }
        };

        this.ws.onmessage = (event) => {
            console.log("WebSocket message: ", event.data);
            const data = JSON.parse(event.data);

            if (data.sequence) {
                updateCameraSequence(data.sequence);
                this.frameCount = data.sequence.length;
            } else {
                updateCameraPositions(
                    data.x,
                    data.y,
                    data.z,
                    data.rw,
                    data.rx,
                    data.ry,
                    data.rz,
                    data.fov,
                );
            }

            if (this.onMessageReceivedCallback) {
                this.onMessageReceivedCallback(data);
            }
        };

        this.ws.onclose = () => {
            console.log("WebSocket disconnected");
            if (this.onDisconnectCallback) {
                this.onDisconnectCallback();
            }
            setTimeout(() => {
                this.connect();
            }, 1000);
        };

        this.ws.onerror = (error) => {
            console.error("WebSocket error: ", error);
        };
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }

    isConnected(): boolean {
        if (!this.ws) {
            return false;
        }
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }

    getFrameCount() {
        return this.frameCount;
    }
}

export const webSocketService = new WebSocketService();
