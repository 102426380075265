export const cameraState = {
    x: 3220,
    y: 0,
    z: 3220,
    rw: 0,
    rx: 0,
    ry: 0,
    rz: 0,
    fov: 90,
};

export const cameraSequence: any[] = [];

export let playing: boolean = false;
export function setPlaying(newPlaying: boolean) {
    playing = newPlaying;
}

export let frameIndex: number = 0;
export function setFrameIndex(index: number) {
    frameIndex = index;
}
export function totalFrames() {
    return cameraSequence.length;
}

export function updateCameraPositions(
    x: number,
    y: number,
    z: number,
    rw: number,
    rx: number,
    ry: number,
    rz: number,
    fov: number,
) {
    cameraState.x = x;
    cameraState.y = y;
    cameraState.z = z;
    cameraState.rw = rw;
    cameraState.rx = rx;
    cameraState.ry = ry;
    cameraState.rz = rz;
    cameraState.fov = fov;
    console.log("Updated camera state:", cameraState);
}

export function updateCameraSequence(sequence: any) {
    cameraSequence.length = 0;
    sequence.forEach((cameraState: any) => {
        cameraSequence.push(cameraState);
    });
    console.log("Updated camera sequence:", cameraSequence);
}
